var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { class: ["Table", { "Table--mobile": _vm.mobile.isMobile }] },
    [
      _vm._t("default"),
      !_vm.mobile.isMobile
        ? _c(
            "div",
            { staticClass: "Table__head-wrapper row row--justify-between" },
            _vm._l(_vm.columnHeaders, function(columnHeader, index) {
              return _c(
                "label",
                {
                  key: index,
                  class:
                    "Table__header-lbl Table__header-lbl--" + _vm.whichTable
                },
                [_vm._v("\n      " + _vm._s(columnHeader) + "\n    ")]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "transition-group",
        { attrs: { name: "Transition__slide-fade" } },
        [_vm._t("rows")],
        2
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }