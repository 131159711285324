var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "BaseProcessingNote" },
    [
      _c(
        "div",
        {
          class: [
            // eslint-disable-next-line max-len
            "Table__item--ClientNotes Table__item-wrapper Table__item-wrapper--ClientNotes row clickable",
            {
              bg: _vm.expanded || _vm.removing
            }
          ],
          attrs: { tabindex: "0" },
          on: {
            click: function($event) {
              _vm.expanded = !_vm.expanded
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [
          _c("div", { staticClass: "column column--justify-center" }, [
            _c("i", {
              class: [
                "fa fa-oval fa-7 fa--none",
                {
                  "fc-green": _vm.note.status === "active",
                  "fc-yellow": _vm.note.status === "archived"
                }
              ]
            })
          ]),
          _c("div", { staticClass: "column" }, [
            _c(
              "time",
              {
                staticClass: "fc-light fs-14 uppercase",
                attrs: {
                  datetime: _vm._f("datetime")(
                    _vm.note.created_at,
                    "YYYY-MM-DD hh:mm:ssZ"
                  )
                }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("datetime")(_vm.note.created_at, "MM/DD/YYYY")
                    ) +
                    "\n      "
                )
              ]
            )
          ]),
          _vm.note.debtor && _vm.note.client
            ? _c("div", { staticClass: "column" }, [
                _c("label", { staticClass: "fc-light fs-15 uppercase" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.type === "client"
                          ? _vm.note.debtor.name
                          : _vm.note.client.name
                      ) +
                      "\n      "
                  )
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "column" }, [
            _c("p", { staticClass: "BaseProcessingNote__note-lbl fs-15" }, [
              _vm._v("\n        " + _vm._s(_vm.configuredNote) + "\n      ")
            ])
          ]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.allowActions,
                  expression: "allowActions"
                }
              ],
              staticClass: "column"
            },
            [
              _c(
                "div",
                { staticClass: "row row--align-center" },
                [
                  _c(
                    "base-button",
                    {
                      staticClass: "ic-light",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.editing = true
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-edit fa--none" })]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "ic-light",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          _vm.removing = true
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-trash-can fa--none" })]
                  )
                ],
                1
              )
            ]
          )
        ]
      ),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__slide-fade" } },
        [
          _vm.removing
            ? _c(
                "div",
                {
                  staticClass:
                    "BaseProcessingNote__remove-container row row--align-center row--justify-end"
                },
                [
                  _c("label", { staticClass: "fs-14 mr-10" }, [
                    _vm._v("Are you sure you want to delete this note?")
                  ]),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-light fc-white mr-10",
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.cancel($event)
                        }
                      }
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _c(
                    "base-button",
                    {
                      staticClass: "bg-blue fc-white",
                      attrs: { disabled: _vm.disableButton },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          return _vm.saveRemove($event)
                        }
                      }
                    },
                    [_vm._v("\n        Delete\n      ")]
                  )
                ],
                1
              )
            : _vm._e()
        ]
      ),
      _c(
        "transition",
        { attrs: { mode: "out-in", name: "Transition__slide-fade" } },
        [
          _vm.editing
            ? _c("base-create-processing-note", {
                attrs: {
                  "client-id": _vm.client.id,
                  "include-debtor": !!_vm.note.debtor && _vm.type === "client",
                  note: _vm.note,
                  type: _vm.type
                },
                on: {
                  cancel: function($event) {
                    _vm.editing = false
                  },
                  edited: _vm.saveEdit
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }