var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-modal", {
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function() {
          return [
            _vm._v(
              "\n    " +
                _vm._s((_vm.note ? "Edit" : "Create") + " Note") +
                "\n  "
            )
          ]
        },
        proxy: true
      },
      {
        key: "input",
        fn: function() {
          return [
            _c("div", { staticClass: "column mb-23" }, [
              _c("label", { staticClass: "fc-mid fs-12 mb-3 uppercase" }, [
                _vm._v("Date")
              ]),
              _c("time", { staticClass: "fc-light fs-19 capitalize" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("datetime")(
                        _vm.note ? _vm.note.created_at : new Date(),
                        "MMM D, YYYY"
                      )
                    ) +
                    "\n      "
                )
              ])
            ]),
            _vm.includeStatus
              ? _c(
                  "div",
                  { staticClass: "column mb-23" },
                  [
                    _c(
                      "label",
                      { staticClass: "fc-mid fs-12 mb-3 uppercase" },
                      [_vm._v("Status")]
                    ),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: ["active", "inactive"]
                      },
                      model: {
                        value: _vm.dataStatus,
                        callback: function($$v) {
                          _vm.dataStatus = $$v
                        },
                        expression: "dataStatus"
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.includeDebtor
              ? _c(
                  "div",
                  { staticClass: "column mb-23" },
                  [
                    _c(
                      "label",
                      { staticClass: "fc-mid fs-12 mb-3 uppercase" },
                      [_vm._v("Debtor")]
                    ),
                    _c("base-debtor-search", {
                      staticClass: "width-100",
                      attrs: {
                        "debtor-i-d-from-query": _vm.note
                          ? _vm.note.debtor.id
                          : null,
                        "hide-debtor-not-found": true,
                        placeholder: "Please select a debtor",
                        valid: _vm.debtor.valid
                      },
                      on: {
                        selected: function($event) {
                          _vm.debtor.value = $event ? $event.id : null
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "column mb-23" },
              [
                _c("label", { staticClass: "fc-mid fs-12 mb-3 uppercase" }, [
                  _vm._v("Note")
                ]),
                _c("base-textarea", {
                  staticClass: "width-100",
                  attrs: { rows: "6" },
                  model: {
                    value: _vm.dataNote,
                    callback: function($$v) {
                      _vm.dataNote = typeof $$v === "string" ? $$v.trim() : $$v
                    },
                    expression: "dataNote"
                  }
                })
              ],
              1
            )
          ]
        },
        proxy: true
      },
      {
        key: "actions",
        fn: function() {
          return [
            _c(
              "base-button",
              {
                staticClass: "bg-light fc-white mr-10 width-100",
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.$emit("cancel")
                  }
                }
              },
              [_vm._v("\n      Cancel\n    ")]
            ),
            _c(
              "base-button",
              {
                staticClass: "bg-blue fc-white width-100",
                attrs: { disabled: _vm.buttonDisabled || !_vm.validation },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    _vm.note ? _vm.edit() : _vm.create()
                  }
                }
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s("" + (_vm.note ? "Save" : "Create")) +
                    "\n    "
                )
              ]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }