<template>
  <div class="BaseProcessingNote">
    <div
      @click="expanded = !expanded"
      @keydown.enter="expanded = !expanded"
      :class="[
        // eslint-disable-next-line max-len
        'Table__item--ClientNotes Table__item-wrapper Table__item-wrapper--ClientNotes row clickable',
        {
          'bg': expanded || removing,
        }
      ]"
      tabindex="0"
    >
      <!-- STATUS -->
      <div class="column column--justify-center">
        <i
          :class="['fa fa-oval fa-7 fa--none', {
            'fc-green': note.status === 'active',
            'fc-yellow': note.status === 'archived',
          }]"
        />
      </div>

      <!-- DATE -->
      <div class="column">
        <time
          class="fc-light fs-14 uppercase"
          :datetime="note.created_at | datetime('YYYY-MM-DD hh:mm:ssZ')"
        >
          {{ note.created_at | datetime('MM/DD/YYYY') }}
        </time>
      </div>

      <!-- DEBTOR or CLIENT -->
      <div
        v-if="note.debtor && note.client"
        class="column"
      >
        <label class="fc-light fs-15 uppercase">
          {{
            type === 'client' ? note.debtor.name : note.client.name
          }}
        </label>
      </div>

      <!-- NOTE -->
      <div class="column">
        <!-- If the font-size is changed, the height of the first table column needs changed -->
        <p class="BaseProcessingNote__note-lbl fs-15">
          {{ configuredNote }}
        </p>
      </div>

      <!-- ACTIONS (hidden for debtor's notes) -->
      <div
        v-show="allowActions"
        class="column"
      >
        <div class="row row--align-center">
          <base-button
            @click.stop="editing = true"
            class="ic-light"
          >
            <i class="fa fa-edit fa--none" />
          </base-button>
          <base-button
            @click.stop="removing = true"
            class="ic-light"
          >
            <i class="fa fa-trash-can fa--none" />
          </base-button>
        </div>
      </div>
    </div>

    <!-- DELETING -->
    <transition
      mode="out-in"
      name="Transition__slide-fade"
    >
      <div
        v-if="removing"
        class="BaseProcessingNote__remove-container row row--align-center row--justify-end"
      >
        <label class="fs-14 mr-10">Are you sure you want to delete this note?</label>
        <base-button
          @click.stop="cancel"
          class="bg-light fc-white mr-10"
        >
          Cancel
        </base-button>
        <base-button
          @click.stop="saveRemove"
          class="bg-blue fc-white"
          :disabled="disableButton"
        >
          Delete
        </base-button>
      </div>
    </transition>

    <!-- EDITING -->
    <transition
      mode="out-in"
      name="Transition__slide-fade"
    >
      <base-create-processing-note
        v-if="editing"
        @cancel="editing = false"
        @edited="saveEdit"
        :client-id="client.id"
        :include-debtor="!!note.debtor && type === 'client'"
        :note="note"
        :type="type"
      />
    </transition>
  </div>
</template>

<script>
import { ProcessingNotes } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseCreateProcessingNote from './base-create-processing-note.vue'

export default {
  name: 'BaseProcessingNote',

  components: {
    BaseButton,
    BaseCreateProcessingNote,
  },

  props: {
    allowActions: {
      type: Boolean,
      required: false,
      default: true,
    },
    note: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'client',
    },
  },

  data () {
    return {
      disableButton: false,
      editing: false,
      expanded: false,
      removing: false,
    }
  },

  computed: {
    client () {
      return this.$store.getters.client
    },

    // Taking the place of a truly responsive, truncating note label
    // If page-width is less than full, it will break over onto a new line
    configuredNote () {
      if (this.expanded) return this.note.notes
      if (this.note.notes.length > 90) {
        // If note is a debtor's note
        if (this.type === 'debtor') return `${this.note.notes.slice(0, 105)}...`
        // If note is a specific note
        if (this.note.client_id && this.note.debtor_id) return `${this.note.notes.slice(0, 60)}...`
        // If note is a general note
        return `${this.note.notes.slice(0, 90)}...`
      }
      return this.note.notes
    },
  },

  methods: {
    cancel () {
      this.editing = false
      this.expanded = false
      this.disableButton = false
      this.removing = false
    },

    saveEdit () {
      this.editing = false
      this.$emit('edited')
    },

    async saveRemove () {
      this.progressStart()
      this.disableButton = true

      try {
        await ProcessingNotes.delete(this.note.id)
        this.disableButton = false
        this.removing = false
        this.$emit('deleted')
        this.progressFinish()
      }
      catch (error) {
        this.disableButton = false
        this.captureSentryEvent(
          'Client Note "Edit"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an editing the note' })
      }
    },
  },
}
</script>

<style lang="sass">
.BaseProcessingNote
  position: relative

  .v-select,
  .vs__dropdown-menu
    min-width: 0
    width: 120px

  &__remove-container
    background-color: $background
    left: 0
    padding: 1rem
    position: absolute
    top: 0

  &__remove-container-bg
    background-color: $background
    opacity: 0.5

  &__note-lbl
    word-break: break-all
</style>