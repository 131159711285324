<template>
  <div :class="['Table', { 'Table--mobile': mobile.isMobile }]">
    <!-- If you want a heading and subheading in the top of the table -->
    <!-- Content passed here should have class: Table__slot-content -->
    <slot />

    <!-- COLUMN HEADERS -->
    <div
      v-if="!mobile.isMobile"
      class="Table__head-wrapper row row--justify-between"
    >
      <label
        v-for="(columnHeader, index) in columnHeaders"
        :key="index"
        :class="`Table__header-lbl Table__header-lbl--${whichTable}`"
      >
        {{ columnHeader }}
      </label>
    </div>

    <!-- ROWS -->
    <transition-group name="Transition__slide-fade">
      <slot name="rows" />
    </transition-group>
  </div>
</template>

<script>
export default {
  name: 'BaseTable',

  props: {
    columnHeaders: {
      type: Array,
      required: true,
    },
    whichTable: {
      type: String,
      required: true,
    },
  },

  computed: {
    mobile () {
      return this.$store.getters.mobile
    },
  },
}
</script>

<style lang="sass">
@import '../styles/tables'
</style>