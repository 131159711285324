<template>
  <base-modal>
    <template v-slot:label>
      {{ `${note ? 'Edit' : 'Create'} Note` }}
    </template>
    <template v-slot:input>
      <div class="column mb-23">
        <label class="fc-mid fs-12 mb-3 uppercase">Date</label>
        <time class="fc-light fs-19 capitalize">
          {{
            note
              ? note.created_at
              : new Date() | datetime('MMM D, YYYY')
          }}
        </time>
      </div>
      <div
        v-if="includeStatus"
        class="column mb-23"
      >
        <label class="fc-mid fs-12 mb-3 uppercase">Status</label>
        <v-select
          v-model="dataStatus"
          :clearable="false"
          :options="['active', 'inactive']"
        />
      </div>
      <div
        v-if="includeDebtor"
        class="column mb-23"
      >
        <label class="fc-mid fs-12 mb-3 uppercase">Debtor</label>
        <base-debtor-search
          @selected="debtor.value = $event ? $event.id : null"
          class="width-100"
          :debtor-i-d-from-query="note ? note.debtor.id : null"
          :hide-debtor-not-found="true"
          :placeholder="'Please select a debtor'"
          :valid="debtor.valid"
        />
      </div>
      <div class="column mb-23">
        <label class="fc-mid fs-12 mb-3 uppercase">Note</label>
        <base-textarea
          v-model.trim="dataNote"
          class="width-100"
          :rows="'6'"
        />
      </div>
    </template>
    <template v-slot:actions>
      <base-button
        @click.stop="$emit('cancel')"
        class="bg-light fc-white mr-10 width-100"
      >
        Cancel
      </base-button>
      <base-button
        @click.stop="note ? edit() : create()"
        class="bg-blue fc-white width-100"
        :disabled="buttonDisabled || !validation"
      >
        {{ `${note ? 'Save' : 'Create'}` }}
      </base-button>
    </template>
  </base-modal>
</template>

<script>
// Helpers
import { ProcessingNotes } from '../utils/api'
// Components
import BaseButton from './base-button.vue'
import BaseDebtorSearch from './base-debtor-search.vue'
import BaseModal from './base-modal.vue'
import BaseTextarea from './base-textarea.vue'

export default {
  name: 'BaseCreateProcessingNote',

  components: {
    BaseButton,
    BaseDebtorSearch,
    BaseModal,
    BaseTextarea,
  },

  props: {
    // Whether or not a user is creating a specific note (requires a debtor)
    clientId: {
      type: String,
      required: false,
      default: null,
    },
    debtorId: {
      type: String,
      required: false,
      default: null,
    },
    includeDebtor: {
      type: Boolean,
      required: false,
      default: false,
    },
    // true when editing a note
    includeStatus: {
      type: Boolean,
      required: false,
      default: true,
    },
    note: {
      type: Object,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: 'client',
    },
  },

  created () {
    if (this.note) {
      this.dataNote = this.note.notes
      this.dataStatus = this.note.status === 'active'
        ? this.note.status
        : 'inactive'
      this.debtor.value = this.note.debtor ? this.note.debtor.id : null
    }
  },

  data () {
    return {
      buttonDisabled: false,
      dataNote: null,
      dataStatus: 'active',
      debtor: {
        valid: true,
        value: this.debtorId,
      },
    }
  },

  computed: {
    validation () {
      let valid = true

      if (this.includeDebtor && !this.debtor.value) valid = false

      if (!this.dataNote) valid = false

      return valid
    },
  },

  methods: {
    async create () {
      this.progressStart()
      this.buttonDisabled = true

      try {
        await ProcessingNotes.create({
          client_id: this.clientId,
          debtor_id: this.debtor.valid && this.debtor.value
            ? this.debtor.value
            : null,
          notes: this.dataNote,
          status: 'active',
          type: this.type
        })
        this.$emit('created')
        this.buttonDisabled = false
        this.progressFinish()
      }
      catch (error) {
        this.buttonDisabled = false
        this.captureSentryEvent(
          'Client Note "Edit"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an issue editing the note' })
      }
    },

    async edit () {
      this.progressStart()
      this.buttonDisabled = true

      try {
        await ProcessingNotes.update({
          client_id: this.clientId,
          debtor_id: this.debtor.valid && this.debtor.value
            ? this.debtor.value
            : null,
          id: this.note.id,
          notes: this.dataNote,
          status: this.dataStatus === 'active'
            ? 'active'
            : 'archived',
          type: this.type
        })

        this.$emit('edited')
        this.buttonDisabled = false
        this.progressFinish()
      }
      catch (error) {
        this.buttonDisabled = false
        this.captureSentryEvent(
          'Client Note "Edit"',
          {
            config: error.config,
            data: this.$data,
            details: error,
            props: this.$props,
            response: error.response,
          }
        )
        this.CError(error)
        this.requestFailure({ message: 'There was an editing the note' })
      }
    },
  },
}
</script>